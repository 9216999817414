<template>
  <b-card no-body>
    <b-card-body>
      <filter-card :get-data-list="getDataList" />
    </b-card-body>
    <template v-if="dataList.length > 0">
      <b-table
        class="mt-1"
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(subject)="data">
          <div class="text-warning font-small-2">
            {{ data.item.customer_user }}
          </div>
          {{ data.item.subject }}
          <div class="font-small-2 text-primary">
            {{ moment(data.item.created).format('llll') }}
          </div>
        </template>
        <template #cell(support_status)="data">
          <div>
            {{ data.item.support_status }}
          </div>
        </template>
        <template #cell(priority)="data">
          <b-badge
            v-if="data.item.id_priorities === '1'"
            variant="light-danger"
            class="w-100"
          >
            {{ data.item.priority }}
          </b-badge>
          <b-badge
            v-if="data.item.id_priorities === '2'"
            variant="light-info"
            class="w-100"
          >
            {{ data.item.priority }}
          </b-badge>
          <b-badge
            v-if="data.item.id_priorities === '3'"
            variant="light-secondary"
            class="w-100"
          >
            {{ data.item.priority }}
          </b-badge>
        </template>
        <template #cell(control)="data">
          <list-buttons
            primary-text="Görüntüle"
            :primary-action="$route.path + '/view/' + data.item.id"
            :edit-action="$route.path + '/edit/' + data.item.id"
            :delete-action="removeData"
            :data-id="data.item.id"
          />
        </template>
      </b-table>
      <b-card-footer>
        <div class="d-flex justify-content-between align-items-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
          <div class="text-muted font-small-2">
            Toplam {{ dataCounts }} adet kayıt bulundu.
          </div>
        </div>
      </b-card-footer>
    </template>
    <template v-else>
      <b-alert show>
        <div class="alert-body text-center mt-2">
          Tebrikler! 👏<br>Bekleyen hiç talep bulunamadı.
        </div>
      </b-alert>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination,
  BCardFooter, BAlert, BBadge,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilterCard from '@/views/Admin/Supports/elements/FilterCard.vue'

const tableName = 'supports'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BCardFooter,
    BAlert,
    BBadge,
    ListButtons,
    FilterCard,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'subject',
          label: 'KONU',
        },
        {
          key: 'project',
          label: 'PROJE',
          thClass: 'width-150 text-nowrap',
          tdClass: 'width-150 text-nowrap',
        },
        {
          key: 'support_status',
          label: 'DURUM',
          thClass: 'width-150 text-nowrap',
          tdClass: 'width-150 text-nowrap',
        },
        {
          key: 'priority',
          label: 'ÖNCELİK',
          thClass: 'width-150 text-nowrap',
          tdClass: 'width-150 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.subject AS subject`,
          'support_statuses.title AS support_status',
          'projects.title AS project',
          'priorities.title AS priority',
          'customer_users.name AS customer_user',
          `${tableName}.id_priorities AS id_priorities`,
          `${tableName}.created AS created`,
        ],
        order_by: ['supports.id', 'DESC'],
        limit: 20,
        start: 0,
      },
      keyword: null,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['supports/dataList']
    },
    dataCounts() {
      return this.$store.getters['supports/dataCounts']
    },
    saveData() {
      return this.$store.getters['supports/dataSaveStatus']
    },
    filterParams() {
      return this.$store.getters['supports/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getSupportStatuses()
    this.getPriorities()
  },
  methods: {
    getSupportStatuses() {
      this.$store.dispatch('supportStatuses/getDataList', {
        select: [
          'support_statuses.id AS id',
          'support_statuses.title AS title',
        ],
      })
    },
    getPriorities() {
      this.$store.dispatch('priorities/getDataList', {
        select: [
          'priorities.id AS id',
          'priorities.title AS title',
        ],
      })
    },
    getDataList() {
      if (this.filterParams.keyword) {
        this.dataQuery.or_like = {
          'supports.subject': this.filterParams.keyword,
          'customer_users.name': this.filterParams.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.dataQuery.where_in = []
      if (this.filterParams.id_support_statuses.length) {
        this.dataQuery.where_in.push(['supports.id_support_statuses', this.filterParams.id_support_statuses])
      }
      if (this.filterParams.id_priorities.length) {
        this.dataQuery.where_in.push(['supports.id_priorities', this.filterParams.id_priorities])
      }
      this.$store.dispatch('supports/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$store.dispatch('supports/removeData', id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
