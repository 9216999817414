<template>
  <b-row>
    <b-col>
      <v-select
        v-model="filterParams.id_support_statuses"
        :options="supportStatuses"
        label="title"
        :reduce="item => item.id"
        placeholder="Durum"
        multiple
        @input="getDataList"
      />
    </b-col>
    <b-col>
      <v-select
        v-model="filterParams.id_priorities"
        :options="priorities"
        label="title"
        :reduce="item => item.id"
        placeholder="Öncelik Durumu"
        multiple
        @input="getDataList"
      />
    </b-col>
    <b-col>
      <b-form-input
        v-model="filterParams.keyword"
        placeholder="Hızlı Arama..."
        @keydown.enter="getDataList"
      />
    </b-col>
    <b-col
      cols="auto"
      class="text-right"
    >
      <b-button
        variant="primary"
        :to="$route.path + '/customer-search'"
      >
        <FeatherIcon icon="PlusIcon" /> Oluştur
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import vSelect from 'vue-select'
import {
  BButton, BCol, BFormInput, BRow,
} from 'bootstrap-vue'

export default {
  name: 'FilterCard',
  components: {
    BFormInput, BButton, BRow, BCol, vSelect,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['supports/filterParams']
    },
    supportStatuses() {
      return this.$store.getters['supportStatuses/dataList']
    },
    priorities() {
      return this.$store.getters['priorities/dataList']
    },
  },
}
</script>
